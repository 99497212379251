import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-cart-recommendation',
    templateUrl: 'cart-recommendation.component.html',
    styleUrls: ['cart-recommendation.component.scss'],
    standalone: true,
    imports: [MatButton]
})
export class CartRecommendationComponent {
}
