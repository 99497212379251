<div *ngIf="product" class="product">
    <div class="product__image">
        <img [src]="product.picture" alt="{{product.name}} picture">
    </div>
    <div class="product__info">
        <div class="product__title">
            <span>{{ product.name }}</span>
        </div>
        <div *ngIf="product.stockQuantity" class="product__quantity">
            <ng-container *ngIf="!cartQuantityLoad; else quantityLoader">
                <app-input-number-field [formControl]="quantityCtrl" min="1" [max]="product.stockQuantity" [disabledCtrl]="!cartEditable"></app-input-number-field>
            </ng-container>
            <ng-template #quantityLoader>
                <app-loader></app-loader>
            </ng-template>
        </div>
    </div>
    <div class="product__right">
        <div class="product__close" *ngIf="cartEditable">
            <button type="button" (click)="removeProduct()">
                <span class="icon-fermer-small"></span>
            </button>
        </div>
        <div *ngIf="!cartQuantityLoad" class="product__price">
            <span>{{ product.subtotal }}€</span>
        </div>
    </div>
</div>
