import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgIf } from '@angular/common';

import { ICartItem } from '../../../../../core/interfaces';
import { GlobalCartService, UtilsService } from '../../../../../core/services';
import { LoaderComponent } from '../../../loader/components';
import { InputNumberFieldComponent } from '../../../../components';

@Component({
    selector: 'app-cart-product',
    templateUrl: 'cart-product.component.html',
    styleUrls: ['cart-product.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        LoaderComponent,
        InputNumberFieldComponent,
    ],
})
export class CartProductComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public product: ICartItem;
    public quantityCtrl = new UntypedFormControl(1);
    public cartEditable = true;
    public cartQuantityLoad = false;
    private routerEvent: any;

    constructor(
        private readonly globalCartService: GlobalCartService,
        public readonly router: Router,
        private readonly utilsService: UtilsService,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {
        if (utilsService.onBrowser()) {
            this.routerEvent = router.events
                .pipe(filter((e: any) => e instanceof NavigationEnd))
                .subscribe(() => {
                    this.cartEditable = router.url !== '/panier/validation';
                    this.changeDetectorRef.detectChanges();
                });
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'product')) {
            this.quantityCtrl.setValue(this.product.quantity);
        }
    }

    public ngOnInit(): void {
        this.quantityCtrl.valueChanges
            .pipe(
                debounceTime(1100),
                distinctUntilChanged()
            )
            .subscribe((quantity: number) => {
                this.cartQuantityLoad = true;
                this.changeDetectorRef.markForCheck();
                this.globalCartService.addItemInCart(this.product.id, this.product.versionId, false, quantity)
                    .pipe(take(1))
                    .subscribe(() => {
                        this.cartQuantityLoad = false;
                        this.changeDetectorRef.markForCheck();
                    });
            });

        this.cartEditable = this.router.url !== '/panier/validation';
    }

    public removeProduct(): void {
        this.globalCartService.removeItemInCart(this.product.versionId);
    }

    public ngOnDestroy(): void {
        this.routerEvent.unsubscribe();
    }
}
