<section class="recommendation">
    <header class="recommendation__title">
        <h2>Ces articles pourraient aussi vous plaire</h2>
    </header>
    <div class="recommendation__list">
        <div class="recommendation__item">
            <div class="recommendation__item--top">
                <div class="recommendation__item--image">
                    <img src="https://via.placeholder.com/100x125" alt="">
                </div>
                <div class="recommendation__item-fullwidth">
                    <div class="recommendation__item--info">
                        <div class="recommendation__item--part">
                            <div class="recommendation__item--title">
                                <span>Magazine Saveurs Green N4</span>
                            </div>
                            <div class="recommendation__item--description">
                                <span>Version magazine</span>
                            </div>
                        </div>
                        <div class="recommendation__item--price">
                            <span>5,00€</span>
                        </div>
                    </div>
                    <div class="recommendation__item--buttons-group">
                        <div class="recommendation__item--button-discover">
                            <button mat-stroked-button type="button" class="btn btn-border">Découvrir</button>
                        </div>
                        <div>
                            <button mat-stroked-button type="button" class="btn btn-blue">Ajouter au panier</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="recommendation__item--bottom">
                <div>
                    <button mat-stroked-button type="button" class="btn btn-blue recommendation__item--button">Ajouter au panier</button>
                </div>
                <div>
                    <a class="recommendation__item--detail">Plus de détail</a>
                </div>
            </div>
        </div>
        <div class="recommendation__item">
            <div class="recommendation__item--top">
                <div class="recommendation__item--image">
                    <img src="https://via.placeholder.com/100x125" alt="">
                </div>
                <div class="recommendation__item-fullwidth">
                    <div class="recommendation__item--info">
                        <div class="recommendation__item--part">
                            <div class="recommendation__item--title">
                                <span>Magazine Saveurs Green N4</span>
                            </div>
                            <div class="recommendation__item--description">
                                <span>Version magazine</span>
                            </div>
                        </div>
                        <div class="recommendation__item--price">
                            <span>5,00€</span>
                        </div>
                    </div>
                    <div class="recommendation__item--buttons-group">
                        <div class="recommendation__item--button-discover">
                            <button mat-stroked-button type="button" class="btn btn-border">Découvrir</button>
                        </div>
                        <div>
                            <button mat-stroked-button type="button" class="btn btn-blue">Ajouter au panier</button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="recommendation__item--bottom">
                <div>
                    <button mat-stroked-button type="button" class="btn btn-blue recommendation__item--button">Ajouter au panier</button>
                </div>
                <div>
                    <a class="recommendation__item--detail">Plus de détail</a>
                </div>
            </div>
        </div>
    </div>
</section>


